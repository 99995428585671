import type { RouteParam } from '@/types/shared';

export const getCompaniesRoute = () => {
  return '/companies';
};

export const getCreateCompanyRoute = () => {
  return `${getCompaniesRoute()}/create`;
};

export const getCompanyRoute = (companyId: RouteParam) => {
  return `${getCompaniesRoute()}/${companyId}`;
};

export const getCompanyUsersRoute = (companyId: RouteParam) => {
  return `${getCompanyRoute(companyId)}/users`;
};

export const getCompanyCreateUserRoute = (companyId: RouteParam) => {
  return `${getCompanyUsersRoute(companyId)}/create`;
};

export const getCompanyUserRoute = (companyId: RouteParam, userId: RouteParam) => {
  return `${getCompanyUsersRoute(companyId)}/${userId}`;
};

export const getCompanyDeleteUserRoute = (companyId: RouteParam, userId: RouteParam) => {
  return `${getCompanyUserRoute(companyId, userId)}/delete`;
};

export const getCompanyEditUserRoute = (companyId: RouteParam, userId: RouteParam) => {
  return `${getCompanyUserRoute(companyId, userId)}/edit`;
};

export const getEditCompanyRoute = (companyId: RouteParam) => {
  return `${getCompanyRoute(companyId)}/edit`;
};

export const getDeleteCompanyRoute = (companyId: RouteParam) => {
  return `${getCompanyRoute(companyId)}/delete`;
};

export const getCompanyTrademarksRoute = (companyId: RouteParam) => {
  return `${getCompanyRoute(companyId)}/trademarks`;
};

export const getCompanyTrademarkRoute = (companyId: RouteParam, trademarkId: RouteParam) => {
  return `${getCompanyTrademarksRoute(companyId)}/${trademarkId}`;
};

export const getDeleteCompanyTrademarkRoute = (companyId: RouteParam, trademarkId: RouteParam) => {
  return `${getCompanyTrademarkRoute(companyId, trademarkId)}/delete`;
};

export const getEditCompanyTrademarkRoute = (companyId: RouteParam, trademarkId: RouteParam) => {
  return `${getCompanyTrademarkRoute(companyId, trademarkId)}/edit`;
};

export const getCreateCompanyTrademarkRoute = (companyId: RouteParam) => {
  return `${getCompanyTrademarksRoute(companyId)}/create`;
};

export const getCompanyBotsRoute = (companyId: RouteParam) => {
  return `${getCompanyRoute(companyId)}/harvesters`;
};

export const getCompanyBotRoute = (companyId: RouteParam, botId: RouteParam) => {
  return `${getCompanyBotsRoute(companyId)}/${botId}`;
};

export const getCreateCompanyBotRoute = (companyId: RouteParam) => {
  return `${getCompanyBotsRoute(companyId)}/create`;
};

export const getCreateMultipleCompanyBotsRoute = (companyId: RouteParam) => {
  return `${getCreateCompanyBotRoute(companyId)}/multiple`;
};

export const getDeleteCompanyBotRoute = (companyId: RouteParam, botId: RouteParam) => {
  return `${getCompanyBotsRoute(companyId)}/${botId}/delete`;
};

export const getEditCompanyBotRoute = (companyId: RouteParam, botId: RouteParam) => {
  return `${getCompanyBotRoute(companyId, botId)}/edit`;
};

export const getCompanyNotificationTemplatesRoute = (companyId: RouteParam) => {
  return `${getCompanyRoute(companyId)}/notification-templates`;
};

export const getCompanyNotificationTemplateRoute = (
  companyId: RouteParam,
  notificationTemplateId: RouteParam,
) => {
  return `${getCompanyNotificationTemplatesRoute(companyId)}/${notificationTemplateId}`;
};

export const getCreateCompanyNotificationTemplateRoute = (companyId: RouteParam) => {
  return `${getCompanyNotificationTemplatesRoute(companyId)}/create`;
};

export const getDeleteCompanyNotificationTemplateRoute = (
  companyId: RouteParam,
  notificationTemplateId: RouteParam,
) => {
  return `${getCompanyNotificationTemplateRoute(companyId, notificationTemplateId)}/delete`;
};

export const getNotificationTemplateDuplicateRoute = (
  companyId: RouteParam,
  notificationTemplateId: RouteParam,
) => {
  return `${getCompanyNotificationTemplateRoute(companyId, notificationTemplateId)}/duplicate`;
};

export const getNotificationTemplateEditRoute = (
  companyId: RouteParam,
  notificationTemplateId: RouteParam,
) => {
  return `${getCompanyNotificationTemplateRoute(companyId, notificationTemplateId)}/edit`;
};

export const getCompanyLoasRoute = (companyId: RouteParam) => {
  return `${getCompanyRoute(companyId)}/letters-of-authorisations`;
};

export const getCompanyLoaRoute = (companyId: RouteParam, loaId: RouteParam) => {
  return `${getCompanyLoasRoute(companyId)}/${loaId}`;
};

export const getCompanyDownloadToSignLoaRoute = (companyId: RouteParam, loaId: RouteParam) => {
  return `${getCompanyLoaRoute(companyId, loaId)}/download-to-sign`;
};

export const getCompanyUploadSignedLoaRoute = (companyId: RouteParam, loaId: RouteParam) => {
  return `${getCompanyLoaRoute(companyId, loaId)}/upload-signed`;
};

export const getCreateCompanyLoaRoute = (companyId: RouteParam) => {
  return `${getCompanyLoasRoute(companyId)}/create`;
};

export const getCompanyEditLoaRoute = (companyId: RouteParam, loaId: RouteParam) => {
  return `${getCompanyLoaRoute(companyId, loaId)}/edit`;
};

export const getCompanyDuplicateLoaRoute = (companyId: RouteParam, loaId: RouteParam) => {
  return `${getCompanyLoaRoute(companyId, loaId)}/duplicate`;
};

export const getCompanyEnforcementPolicyPreviewRoute = (companyId: RouteParam) => {
  return `${getCompanyRoute(companyId)}/enforcement-policy-preview`;
};

export const getCompanyBrandsRoute = (companyId: RouteParam) => {
  return `${getCompanyRoute(companyId)}/brands`;
};

export const getCompanyBrandRoute = (companyId: RouteParam, brandId: RouteParam) => {
  return `${getCompanyBrandsRoute(companyId)}/${brandId}`;
};
export const getCompanyCreateBrandRoute = (companyId: RouteParam) => {
  return `${getCompanyBrandsRoute(companyId)}/create`;
};

export const getCompanyEditBrandRoute = (companyId: RouteParam, brandId: RouteParam) => {
  return `${getCompanyBrandRoute(companyId, brandId)}/edit`;
};

export const getCompanyDeleteBrandRoute = (companyId: RouteParam, brandId: RouteParam) => {
  return `${getCompanyBrandRoute(companyId, brandId)}/delete`;
};

export const getCompanyEnforcementPoliciesRoute = (companyId: RouteParam) => {
  return `${getCompanyRoute(companyId)}/enforcement-policies`;
};
